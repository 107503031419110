/* Dashboard.css */
.dashboard-root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #f9fafb;
}

.dashboard-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
}

/* Top Navigation */
.top-nav {
  padding: 16px 24px;
  background-color: white;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 16px;
}

.nav-link {
  color: #3b82f6;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 6px;
}

.nav-link.active {
  background-color: #e5e7eb;
  color: #333;
}

.nav-link:hover {
  color: #1d4ed8;
  background-color: #f3f4f6;
}

.back-button {
  color: #3b82f6;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
}

.back-button:hover {
  background-color: #f3f4f6;
}

/* Tab Container */
.tab-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.tab-buttons {
  display: flex;
  gap: 16px;
}

.tab-button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: #e5e7eb;
}

.tab-button.active {
  background-color: #3b82f6;
  color: white;
}

.add-user-button {
  padding: 8px 16px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-user-button:hover {
  background-color: #2563eb;
}

/* Table Wrapper */
.table-wrapper {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

/* Keep all your existing styles below this point */
.search-container {
  margin-bottom: 24px;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
}
  
  /* Messages */
  .error-message {
    padding: 16px;
    background-color: #fee2e2;
    color: #dc2626;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .success-message {
    padding: 16px;
    background-color: #dcfce7;
    color: #16a34a;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  /* New User Form */
  .new-user-form {
    border: 1px solid #d1d5db;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 8px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"] {
    padding: 8px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  
  .create-button {
    padding: 8px 16px;
    background-color: #22c55e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .create-button:hover {
    background-color: #16a34a;
  }
  
  /* Table */
  .table-container {
    overflow-x: auto;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .users-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .users-table th {
    background-color: #f3f4f6;
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
    position: relative; /* Added */
    white-space: nowrap; /* Added */
  }
  
  
  .copy-button {
    padding: 4px;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .copy-button:hover {
    background-color: #f3f4f6;
  }
  
  .status-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .status-active {
    background-color: #dcfce7;
    color: #16a34a;
  }
  
  .status-inactive {
    background-color: #f3f4f6;
    color: #4b5563;
  }
  
  .action-buttons {
    display: flex;
    gap: 8px;
  }
  
  .action-button {
    padding: 4px 8px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: white;
  }
  
  .action-button.grant {
    background-color: #22c55e;
  }
  
  .action-button.revoke {
    background-color: #ef4444;
  }
  
  .action-button:hover {
    opacity: 0.9;
  }
  
  .loading {
    text-align: center;
    padding: 24px;
  }
  
  .no-results {
    text-align: center;
    padding: 24px;
    color: #6b7280;
  }

  .show-scrollbar {
    overflow: auto !important; /* Enable scrolling */
  }
  
  /* Re-enable the scrollbar for WebKit browsers */
  .show-scrollbar::-webkit-scrollbar {
    display: block !important;
    width: 8px; /* Adjust width */
  }
  
  .show-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3); /* Scrollbar thumb color */
    border-radius: 4px;
  }
  
  .show-scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Scrollbar track color */
  }
  

/* --------------------------------- */
/* Add these new styles and modify the existing ones */

/* Modify the existing table header styles */

/* Update the sortable header styles */
.sortable-header {
  cursor: pointer;
  user-select: none;
  display: inline-flex; /* Changed from flex to inline-flex */
  align-items: center;
  gap: 4px;
  width: 100%; /* Added */
  padding: 0; /* Added */
  font-weight: 500; /* Added */
}

/* Add styles for the sort icon container */
.sort-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

/* Add hover effect */
.sortable-header:hover {
  background-color: #f8f9fa;
}

/* Style the active sort column */
.sortable-header.active {
  color: #3b82f6;
}



/* Mobile Compatibility Additions */

/* Base responsive adjustments */
@media screen and (max-width: 768px) {
  .dashboard-root {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  
  .dashboard-content {
    padding: 16px;
  }
  
  /* Adjust navigation for mobile */
  .top-nav {
    padding: 12px 16px;
    flex-direction: column;
    gap: 12px;
  }
  
  .nav-links {
    width: 100%;
    overflow-x: auto;
    justify-content: flex-start;
    padding-bottom: 4px;
  }
  
  .nav-link {
    white-space: nowrap;
    padding: 6px 10px;
    font-size: 14px;
  }
  
  .back-button {
    align-self: flex-end;
  }
  
  /* Adjust tab container for mobile */
  .tab-container {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }
  
  .tab-buttons {
    width: 100%;
    overflow-x: auto;
    justify-content: flex-start;
    padding-bottom: 4px;
  }
  
  .tab-button {
    white-space: nowrap;
    font-size: 14px;
  }
  
  .add-user-button {
    align-self: flex-end;
  }
  
  /* Form adjustments */
  .form-grid {
    grid-template-columns: 1fr;
  }
  
  /* Table adjustments */
  .users-table {
    min-width: 700px; /* Ensure horizontal scrolling for complex tables */
  }
  
  .action-buttons {
    flex-direction: column;
    gap: 4px;
  }
  
  .action-button {
    width: 100%;
    white-space: nowrap;
    font-size: 12px;
    padding: 3px 6px;
  }
}

/* Extra small devices */
@media screen and (max-width: 480px) {
  .dashboard-content {
    padding: 12px;
  }
  
  .nav-link, .tab-button {
    font-size: 13px;
    padding: 5px 8px;
  }
  
  .add-user-button {
    width: 100%;
    margin-top: 8px;
  }
  
  .table-wrapper {
    margin-top: 16px;
  }
  
  /* Adjust table cell padding */
  .users-table td, 
  .users-table th {
    padding: 8px;
    font-size: 13px;
  }
}

/* Make sure the table container allows horizontal scrolling */
.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS */
}

/* Ensure form elements are properly sized on mobile */
@media screen and (max-width: 768px) {
  .form-group input[type="text"],
  .form-group input[type="email"],
  .search-input {
    font-size: 16px; /* Prevents iOS zoom on input focus */
    padding: 10px;
  }
  
  .create-button {
    width: 100%;
    padding: 10px;
  }
  
  .error-message, .success-message {
    padding: 12px;
    font-size: 14px;
  }
}

/* Table Horizontal Scrolling Fix */

/* Ensure the table container properly enables horizontal scrolling */
.table-container {
  width: 100%;
  overflow-x: auto !important; /* Force overflow scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  max-width: 100vw;
  display: block;
  margin: 0;
  padding: 0;
}

/* Make the table maintain its full width */
.users-table {
  min-width: 800px; /* Ensure table is wide enough to need scrolling */
  width: auto;
  table-layout: auto;
}

/* Fix for certain mobile browsers */
.table-wrapper {
  max-width: 100%;
  overflow-x: hidden; /* Prevent double scrollbars */
}

/* Additional mobile-specific fixes */
@media screen and (max-width: 768px) {
  /* Fix possible overflow issues in the dashboard container */
  .dashboard-root {
    overflow-x: hidden;
    width: 100%;
  }
  
  .dashboard-container {
    overflow-x: hidden;
    width: 100%;
  }
  
  .dashboard-content {
    overflow-x: hidden;
    width: 100%;
  }
  
  /* Ensure the table wrapper doesn't cause horizontal overflow */
  .table-wrapper {
    width: 100%;
    overflow-x: hidden;
  }
  
  /* Force the table container to handle the scrolling */
  .table-container {
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin; /* For Firefox */
  }
}

/* Make scrollbars visible in WebKit browsers */
.table-container::-webkit-scrollbar {
  height: 6px;
  display: block;
}

.table-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.action-button.edit {
    background-color: #4a90e2;
    color: white;
}

.action-button.edit:hover {
    background-color: #357abd;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

/* Add these styles to your DashboardNew.css file */

/* Audit Log Table Styles */
.logs-table {
  width: 100%;
  border-collapse: collapse;
}

.logs-table th,
.logs-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.logs-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #555;
}

.expanded-row {
  background-color: #f5f9ff;
}

.expanded-details-row {
  background-color: #f5f9ff;
}

.expanded-details {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 5px 0;
}

.changed-fields-details h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.changes-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
  font-size: 14px;
}

.changes-table th,
.changes-table td {
  padding: 8px;
  border: 1px solid #e0e0e0;
  text-align: left;
}

.changes-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.image-preview img {
  border: 1px solid #ddd;
  border-radius: 4px;
  object-fit: cover;
}

.image-preview a {
  font-size: 12px;
  color: #0066cc;
  text-decoration: none;
}

.image-metadata {
  margin-top: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.image-metadata h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 15px;
}

.image-metadata ul {
  margin: 0;
  padding-left: 20px;
}

.image-metadata li {
  margin-bottom: 5px;
  font-size: 14px;
}

.treks-table {
  width: 100%;
  border-collapse: collapse;
}

.treks-table th,
.treks-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e5e7eb; /* Consistent border with user table */
}

.treks-table th {
  background-color: #f3f4f6; /* Consistent header background */
  font-weight: 500; /* Match header font weight if needed */
}

/* Special style for count links in "Done By (#)" and "Wishlist Of (#)" columns */
.count-link {
  background: none;
  border: none;
  color: #3b82f6; /* Primary blue color for links */
  cursor: pointer;
  padding: 0; /* Remove default button padding */
  font-size: inherit; /* Inherit font size from table cell */
}

.count-link:hover {
  color: #1d4ed8; /* Darker blue on hover */
}

/* Style for Trek name input when editing */
.treks-table td form input[type="text"] {
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%; /* Take full width of the cell */
  box-sizing: border-box; /* Include padding and border in width */
  margin-bottom: 5px; /* Space below input if needed */
}

.treks-table td form button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  font-size: 14px; /* Added font-size to buttons */
}

.treks-table td form button[type="submit"] {
  background-color: #22c55e; /* Green for update */
  color: white;
}

.treks-table td form button[type="submit"]:hover {
  background-color: #16a34a;
}

.treks-table td form button[type="button"] {
  background-color: #f44336; /* Red for cancel */
  color: white;
}

.treks-table td form button[type="button"]:hover {
  background-color: #da190b;
}