.hiking-gear-page {
  min-height: 100vh;
  background-color: #f9fafb;
}

.hiking-gear-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.back-button-container {
  margin-bottom: 1rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: none;
  background: none;
  color: #4b5563;
  cursor: pointer;
  transition: color 0.2s;
}

.back-button:hover {
  color: #1f2937;
}

.page-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.45rem;
  font-family: 'Dela Gothic One', sans-serif;
}

.subtitle {
  text-align: center;
  color: #6b7280;
  margin-bottom: 0.5rem;
  padding: 0px 0px 30px;
}

.gear-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.gear-card {
  background-color: white;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
}

.gear-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.gear-card-header {
  padding: 1rem;
  background-color: white;
}

.gear-category {
  font-size: 1.125rem;
  font-weight: 500;
  color: #111827;
  margin: 0;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gear-card:hover .product-image {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem;
  color: white;
}

.gear-card:hover .image-overlay {
  opacity: 1;
}

.gear-recommender {
  font-size: 0.875rem;
  color: #e5e7eb;
  margin-bottom: 0.5rem;
}

.gear-notes {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
}

.note-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  color: #e5e7eb;
}

.product-link {
  display: inline-block;
  margin-top: 0.5rem;
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
}

.product-link:hover {
  text-decoration: underline;
}

.category-navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.category-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
  font-weight: 500;
}

.category-button.active {
  background-color: #f97316;
  border-color: #f97316;
  color: white;
}

.category-button:hover:not(.active) {
  background-color: #f3f4f6;
  border-color: #d1d5db;
}

.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  text-align: center;
  padding: 2rem 0;
  color: #6b7280;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .gear-grid {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  
  .image-overlay {
      padding: 1rem;
  }
}

.tiny-category {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  z-index: 2;
}

.gear-title-link {
  text-decoration: none;
  color: inherit;
}

.gear-title-link:hover {
  text-decoration: underline;
}

.read-more-button {
  background: none;
  border: none;
  color: #3b82f6;
  cursor: pointer;
  padding: 4px 0;
  font-size: 0.875rem;
  text-decoration: underline;
}

.read-more-button:hover {
  color: #2563eb;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 0.75rem;
  padding: 2rem;
  max-width: 90%;
  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  transition: background-color 0.2s;
}

.modal-close:hover {
  background-color: #f3f4f6;
  color: #1f2937;
}

.modal-header {
  margin-bottom: 1.5rem;
}

.modal-category {
  display: inline-block;
  background-color: #f3f4f6;
  color: #4b5563;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.modal-title a {
  color: inherit;
  text-decoration: none;
}

.modal-title a:hover {
  text-decoration: underline;
}

.modal-recommender {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.modal-notes {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-note-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  color: #374151;
}

.modal-note-item .bullet {
  margin-right: 0.5rem;
  color: #6b7280;
}

/* Ensure consistent card heights */
.gear-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image-container {
  flex: 1;
  min-height: 240px;
}

/* Keep existing styles for hiking-gear-page, hiking-gear-container, etc. */

.gear-card {
  background-color: white;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
  display: flex;
  flex-direction: column;
}

.gear-card.expanded {
  grid-row-end: span 2;
}

.image-section {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
}

.category-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  z-index: 2;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.product-name-link {
  text-decoration: none;
  color: inherit;
}

.product-name {
  font-size: 1.125rem;
  font-weight: 500;
  color:#3b82f6;
  margin: 0;
}

.product-name:hover {
  text-decoration: underline;
}

.recommender {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.thumbs-up {
  color: #3b82f6;
}

.notes-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.note-item {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  color: #374151;
  font-size: 0.875rem;
}

.bullet {
  color: #6b7280;
}

.read-more-button {
  background: none;
  border: none;
  color: #3b82f6;
  cursor: pointer;
  padding: 0;
  font-size: 0.875rem;
  text-align: left;
  margin-top: 0.25rem;
}

.read-more-button:hover {
  text-decoration: underline;
}

.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Keep existing styles for category-navigation, loading-container, etc. */

.product-count {
  text-align: center;
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.hidden {
  display: none;
}

.heart-icon {
  color: #ff4444;
  margin-right: 6px;
}

.recommender {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  color: #666;
}

/* Add Gear Button - Simple Orange Style */
.add-gear-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #f97316; /* Orange color */
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: transform 0.2s, box-shadow 0.2s;
}

.add-gear-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/* Modal - Minimal Style to Match Existing */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
}

/* Form Elements - Minimal Style */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  background-color: #f97316;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

/* Keep existing heart styles */

.heart-button:hover {
  background-color: rgba(255, 0, 0, 0.1);
}


heart-button.hearted {
  color: #ff4444;
}

.heart-button:not(.hearted) {
  color: #9ca3af;
}

.heart-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.heart-icon {
  transition: fill 0.2s;
}

/* Add to your existing HikingGearList.css */

/* Recommender link style */
.recommender-link {
  color: #3b82f6;
  text-decoration: none;
  transition: text-decoration 0.2s;
}

.recommender-link:hover {
  text-decoration: underline;
}

/* Image URL note style */
.image-url-note {
  font-size: 0.75rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
  background-color: #f0f9ff;
  padding: 0.5rem;
  border-radius: 4px;
  border-left: 3px solid #3b82f6;
}

/* Form validation error style */
.error-message {
  color: #ef4444;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #fef2f2;
  border-radius: 4px;
}

/* Loading spinner for hearts */
.heart-button .react-spinner {
  margin: 0;
}

/* Add to your HikingGearList.css */

/* Notes edit styles */
.notes-edit-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notes-edit-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 0.875rem;
  min-height: 100px;
  resize: vertical;
}

.notes-edit-buttons {
  display: flex;
  gap: 0.5rem;
}

.save-notes-button {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
}

.cancel-notes-button {
  background-color: #f3f4f6;
  color: #4b5563;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
}

.edit-notes-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
  color: #3b82f6;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.25rem 0;
  margin-top: 0.5rem;
}

.edit-notes-button:hover {
  text-decoration: underline;
}

/* Form submission loading state */
.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Add to HikingGearList.css */
.heart-button.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-button.loading {
  position: relative;
}

.submit-button.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: translate(-50%, -50%) rotate(360deg); }
}

.header-section {
  margin-bottom: 0.5rem;
}

.page-title {
  margin-bottom: 0.25rem;
}

.subtitle {
  margin-top: 0;
}

/* Heart button styles */
.heart-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  position: relative; /* Needed for absolute positioning of the icon */
}

/* Circular background effect on hover */
.heart-button:hover {
  background: rgba(255, 68, 68, 0.2);
  transform: scale(1.1);
}

/* Ensuring perfect centering using absolute positioning */
.heart-icon {
  width: 24px;
  height: 24px;
  color: #ff4444;
  transition: all 0.2s ease-in-out;
  
  position: absolute; /* Absolute positioning for perfect centering */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Default heart color when not liked */
.heart-button:not(.hearted) .heart-icon {
  color: #9ca3af;
}

/* Hearted state (filled red) */
.heart-button.hearted .heart-icon {
  color: #ff4444;
  fill: currentColor;
}

/* Disabled state */
.heart-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}


/* Product header layout */
.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.product-name {
  margin: 0;
  flex-grow: 1;
}

/* Recommender label */
.recommender-label {
  color: #6b7280;
  font-size: 0.875rem;
}

/* Notes modal styles */
.modal-subtitle {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.modal-buttons {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.skip-notes-button {
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  font-size: 0.875rem;
}

.skip-notes-button:hover {
  text-decoration: underline;
}
.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.add-gear-button-large {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #f97316;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
}

.add-gear-button-large:hover {
  background-color: #ea580c;
}

.page-title {
  margin-bottom: 0.25rem; /* Reduce spacing */
}

.subtitle {
  margin: 0 !important; /* Remove extra spacing */
  padding: 0; /* Ensure no padding */
}

.add-gear-button-large {
  margin: 0 !important; /* Ensure no extra space */
}

.recommender {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0.5rem 0;
}

.recommender-label {
  margin-right: 0.25rem;
}

.recommender-link {
  color: #3b82f6;
  text-decoration: none;
  white-space: nowrap;
}

.recommender-link:hover {
  text-decoration: underline;
}

.instructions-text {
  text-align: center;
  color: #6b7280;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
}

.heart-emoji {
  color: #ff4444;
  font-size: 1.1em;
}

.orange-button {
  background-color: #f97316;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.orange-button:hover {
  background-color: #ea580c;
}

.orange-button:disabled {
  background-color: #fbbf94;
  cursor: not-allowed;
}

