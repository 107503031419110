.table {
  width: 100%;
  table-layout: auto; /* Columns adjust based on content */
}

.table th, .table td {
  max-width: 200px; /* Set a maximum width for columns */
  overflow-wrap: break-word; /* Allows text to break and wrap within the cell */
  white-space: normal; /* Allows text to wrap onto multiple lines */
  vertical-align: top; /* Align text to the top of the cell for better readability */
  word-wrap: break-word; /* Ensures long words will break and wrap */
}

/* Specific styles for column headers to ensure wrapping */
.table th {
  word-wrap: break-word; /* Ensures header text wraps if too long */
  text-align: left; /* Align text to the left for headers */
}

/* Adjust width for specific columns if needed */
.table .user-id, .table .alert-id {
  max-width: 150px;
}

.table .date-of-birth, .table .email, .table .whatsapp-no {
  max-width: 200px;
}

/* Add this to your App.css file */
.dashboard-container {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.card {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.table-responsive {
  margin-top: 20px;
}

.nav-tabs .nav-link {
  color: #495057;
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  color: #007bff;
  font-weight: 600;
}

.badge {
  padding: 8px 12px;
  font-weight: 500;
}

.table th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.table td {
  vertical-align: middle;
}

.card-title {
  color: #495057;
  font-weight: 600;
}

.form-control {
  border-radius: 5px;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Add this to your App.css file */
.dashboard-container {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.card {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.table-responsive {
  margin-top: 20px;
}

.nav-tabs .nav-link {
  color: #495057;
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  color: #007bff;
  font-weight: 600;
}

.badge {
  padding: 8px 12px;
  font-weight: 500;
}

.table th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.table td {
  vertical-align: middle;
}

.card-title {
  color: #495057;
  font-weight: 600;
}

.form-control {
  border-radius: 5px;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.route-details {
  white-space: pre-wrap;
  word-break: break-word;
}

/* Copy button styles */
.btn-outline-primary {
  padding: 0.25rem 0.5rem;
  line-height: 1;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  color: white;
}

/* Add this to your App.css file */
.dashboard-container {
  padding: 20px;
  background-color: #f8f9fa;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.table-container {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.table-responsive {
  height: calc(100vh - 300px); /* Adjust based on your header height */
  overflow-y: auto;
}

.table {
  margin-bottom: 0;
}

thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
  border-bottom: 2px solid #dee2e6;
}

.card {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0)
}
.dashboard-container {
  padding-top: 20px;
}

.sticky-top {
  z-index: 1020;
}

.stat-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Add hover effect to cards */
.hover-effect {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.hover-effect:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Improve table row hover effect */
.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

/* Add some padding and border-radius to cards */
.card {
  border-radius: 10px;
  padding: 15px;
}

/* Improve typography */
.card-title {
  font-size: 1.25rem;
  font-weight: 600;
}

/* .card-text {
  font-size: 2rem;
  font-weight: 700;
} */

/* Add some spacing and border-radius to buttons */
.btn {
  border-radius: 5px;
  padding: 5px 10px;
}

/* Improve the look of the search bar */
.form-control {
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* Add some animation to the spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-border {
  animation: spin 1s linear infinite;
}
