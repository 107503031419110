.form-container {
    position: relative;
    min-height: 100vh;
    background-color: #f0f1f3;
    overflow-x: hidden;
}

.form-container.loading {
    overflow: hidden;
}

.form-inner-container {
    max-width: 480px;
    margin: 0 auto;
    background-color: #f0f1f3;
    height: 100%;
}

.form-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-title {
    font-size: 1.65rem;
    text-align: center;
    font-family: 'Dela Gothic One', sans-serif;
    margin: 0;
    padding: 15px 0;
}

.back-header {
    padding: 1rem 0;
}

.back-link a {
    color: #0000FF;
    text-decoration: none;
    cursor: pointer;
}

.back-link a:hover {
    text-decoration: underline;
}

.form-content {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.form-section {
    padding: 1rem;
}

.form-title {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.helper-text {
    display: block;
    font-size: 0.8rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.form-group input[type="text"],
.form-group input[type="tel"],
.form-group input[type="email"],
.form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
}

.form-group input.error,
.form-group textarea.error {
    border-color: #dc3545;
}

.error-message {
    color: #dc3545;
    font-size: 0.8rem;
    margin-top: 0.25rem;
}

.checkbox-group {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}

.checkbox-group input[type="checkbox"] {
    margin-top: 0.25rem;
}

.checkbox-group label {
    text-transform: none;
    font-size: 0.9rem;
    color: #212529;
}

.submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #ff6e1e;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-button:hover:not(:disabled) {
    background-color: #e85d0f;
}

.submit-button:disabled {
    background-color: #ffa673;
    cursor: not-allowed;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #111827;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
    .form-inner-container {
        width: 100%;
    }
    
    .form-section {
        padding: 1rem 0.5rem;
    }
}