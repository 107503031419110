.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .workshop-page {
    min-height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .workshop-container {
    width: 100%;
    max-width: 64rem;
  }
  
  .workshop-card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .video-container {
    position: relative;
    padding-top: 56.25%;
  }
  
  .video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-overlay {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
  }
  
  .info-button {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .info-button:hover {
    background-color: rgba(255, 255, 255, 1);
    transform: scale(1.1);
  }
  
  .workshop-info-modal h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .workshop-info-modal ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
  }
  
  .workshop-info-modal li {
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  .workshop-content {
    padding: 1rem;
  }
  
  .workshop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .workshop-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .workshop-actions {
    display: flex;
    gap: 1rem;
  }
  
  .back-button, .start-workshop-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease;
  }
  
  .back-button {
    background-color: transparent;
    color: #555;
    border: 1px solid #ddd;
  }
  
  .back-button:hover {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .start-workshop-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .start-workshop-button:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    .workshop-container {
      max-width: 100%;
    }
  
    .workshop-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  
    .workshop-actions {
      width: 100%;
      flex-direction: column;
    }
  
    .back-button, .start-workshop-button {
      width: 100%;
      justify-content: center;
    }
  
    .workshop-title {
      font-size: 1.25rem;
    }
  }

  .workshop-info-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 400px;
    z-index: 20;
  }
  
  @media (max-width: 768px) {
    .workshop-info-modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      border-radius: 0;
    }
  }