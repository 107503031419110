/* AdminProductManagement.css */
.admin-product-management-root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
}

.admin-product-management-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.admin-product-management-content {
  flex: 1;
  padding: 24px;
  display: flex; /* Enable flex layout for main content areas */
  gap: 24px; /* Gap between the two main sections */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

/* Top Navigation */
.admin-product-management-top-nav {
  padding: 16px 24px;
  background-color: white;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-product-management-nav-links {
  display: flex;
  gap: 16px;
}

.admin-product-management-nav-link {
  color: #3b82f6;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 6px;
}

.admin-product-management-nav-link.active {
  background-color: #e5e7eb;
  color: #333;
}

.admin-product-management-nav-link:hover {
  color: #1d4ed8;
  background-color: #f3f4f6;
}

.admin-product-management-back-button {
  color: #3b82f6;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
}

.admin-product-management-back-button:hover {
  background-color: #f3f4f6;
}

/* Add Product and Preview Section */
.add-product-preview-section {
  flex: 1; /* Takes up available space, adjust as needed */
  min-width: 400px; /* Minimum width for this section */
  display: flex;
  flex-direction: column; /* Ensure vertical stacking */
  gap: 20px;
}

.add-product-card,
.product-preview-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.add-product-header,
.product-preview-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  position: relative; /* Add this to make it positioning context */
}

.form-label {
  display: block;
  margin-bottom: 8px;
  color: #555;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  outline: none;
}

.emoji-picker-input-group {
  display: flex;
  align-items: center;
}

.emoji-button {
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #f9fafb;
  cursor: pointer;
}

.emoji-button:hover {
  background-color: #f3f4f6;
}

.add-product-button {
  padding: 10px 20px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
  width: 100%; /* Make button full width */
}

.add-product-button:hover {
  background-color: #2563eb;
}

/* Product Preview Card */
.preview-content {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.preview-icon {
  font-size: 3rem;
  margin-right: 15px;
}

.preview-details h5 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
}

.preview-details .badge {
  display: inline-block;
  padding: 0.3em 0.6em;
  border-radius: 0.25rem;
  font-size: 0.8em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-left: 5px;
  color: white;
}

.preview-details .badge-blue {
  background-color: #3b82f6;
}

.preview-details .badge-member {
  background-color: #22c55e; /* Green color */
}


.preview-description {
  color: #555;
}

/* Products Section */
.products-section {
  flex: 2; /* Takes up more space */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid */
  gap: 16px;
}

.product-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  position: relative;
}

.product-card:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.product-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.product-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.product-description {
  color: #555;
  font-size: 0.9rem;
}

.product-badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.product-badge .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: white;
}

.product-badge .badge-blue {
  background-color: #3b82f6;
}

.product-badge .badge-member {
  background-color: #22c55e; /* Green color */
}


.delete-product-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ef4444;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 8px;
  cursor: pointer;
  font-size: 0.8rem;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.delete-product-button:hover {
  opacity: 1;
}

/* Messages */
.error-message {
  padding: 16px;
  background-color: #fee2e2;
  color: #dc2626;
  border-radius: 4px;
  margin-top: 10px;
}

.success-message {
  padding: 16px;
  background-color: #dcfce7;
  color: #16a34a;
  border-radius: 4px;
  margin-top: 10px;
}

.loading {
  text-align: center;
  padding: 24px;
}

.no-results {
  text-align: center;
  padding: 24px;
  color: #6b7280;
}

/* Emoji Picker Styling - Ensure it's positioned correctly */
.emoji-picker-container {
  position: absolute; /* Or relative, depending on your layout needs */
  z-index: 10; /* Ensure it's above other content */
  margin-top: 5px; /* Adjust as needed */
  left: 0; /* Align to the left of the form-group */
  /* Optionally, you can add a border or background to visually separate it */
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}