/* trek-modal.css */
.trek-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
  }
  .trek-modal-container {
    width: 580px;
    max-width: 95%;
    max-height: 90vh;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }
  .trek-modal-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .trek-modal-header {
    padding: 16px 16px;  /* Increased padding */
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .trek-modal-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding-left: 4px;
  }
  .trek-modal-close-button {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    color: #999;
    padding: 0 8px;  /* Increased horizontal padding */
  }
  .trek-modal-body {
    padding: 16px 16px;  /* Increased padding */
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .trek-modal-search-container {
    position: relative;
    margin-bottom: 12px;  /* Increased margin */
    padding-left: 2px;
    padding-right: 2px;
  }
  .trek-modal-search-input {
    width: 100%;
    padding: 10px 30px 10px 14px;  /* Increased padding */
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
  }
  .trek-modal-search-clear {
    position: absolute;
    right: 12px;  /* Adjusted position */
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #999;
    font-size: 16px;
    cursor: pointer;
    padding: 0 5px;
  }
  .trek-modal-selected-count {
    font-size: 12px;
    color: #888;
    margin-bottom: 8px;  /* Increased margin */
    padding-left: 4px;
  }
  .trek-modal-list {
    flex: 1 1 auto;
    overflow-y: auto;
    max-height: 50vh;
    padding-left: 4px;  /* Increased padding */
    padding-right: 4px;  /* Increased padding */
  }
  .trek-modal-list-item {
    padding: 10px 12px;  /* Increased padding */
    margin: 0 0 6px 0;  /* Increased margin */
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid transparent;
    transition: background-color 0.15s ease;
  }
  .trek-modal-list-item.selected {
    background-color: #fff9f5;
    border: 1px solid #ffceb0;
  }
  .trek-modal-list-item-name {
    flex: 1 1 auto;
    font-size: 14px;
    font-weight: normal;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .trek-modal-list-item-name.selected {
    font-weight: 500;
    color: #ff6e1e;
  }
  .trek-modal-list-item-remove {
    color: #ff3b30;
    margin-left: 12px;  /* Increased margin */
    font-size: 14px;
    font-weight: bold;
    flex-shrink: 0;
  }
  .trek-modal-empty {
    text-align: center;
    padding: 20px 0;  /* Increased padding */
    color: #888;
  }
  .trek-modal-footer {
    padding: 16px 16px;  /* Increased padding */
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    gap: 10px;  /* Increased gap */
  }
  .trek-modal-cancel-button {
    flex: 1 1 auto;
    padding: 10px 0;  /* Increased padding */
    background-color: #f5f5f5;
    color: #666;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .trek-modal-save-button {
    flex: 1 1 auto;
    padding: 10px 0;  /* Increased padding */
    background-color: #ff6e1e;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .trek-modal-header {
      padding: 12px 12px;  /* Less padding than desktop but more than original */
    }
    
    .trek-modal-body {
      padding: 12px 12px;  /* Less padding than desktop but more than original */
    }
    
    .trek-modal-footer {
      padding: 12px 12px;  /* Less padding than desktop but more than original */
    }
    
    .trek-modal-list-item {
      padding: 8px 10px;  /* Slightly reduced from desktop */
    }
    
    .trek-modal-search-input {
      padding: 8px 26px 8px 12px;  /* Slightly reduced from desktop */
    }
  }


  /* Confirmation message styles */
.trek-confirmation-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e7f7ed;
    border-left: 4px solid #28a745;
    color: #155724;
    padding: 12px 16px;
    width: 300px; /* Keep it small */
    max-width: 90%; /* Ensure it fits on mobile */
    border-radius: 6px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease;
    z-index: 9999; /* Ensures it's on top */
  }
  
  .trek-confirmation-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .trek-confirmation-icon {
    font-size: 18px;
    color: #28a745;
  }
  
  .trek-confirmation-text {
    font-size: 14px;
    font-weight: 500;
  }
  
  .trek-confirmation-close {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 16px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: translate(-50%, calc(-50% - 10px)); }
    to { opacity: 1; transform: translate(-50%, -50%); }
  }

  /* Add these styles to your TrekModal.css */
/* Add these to your TrekModal.css */
.trek-modal-add-form {
  padding: 15px;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin: 10px 0;
}

.trek-modal-add-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.trek-modal-add-form h5 {
  margin: 0;
  font-weight: 600;
  font-size: 16px; /* Match the main modal title size */
}

.trek-modal-add-form-subtitle {
  color: #666;
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 15px;
}

.trek-modal-add-form-close {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #666;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.trek-modal-form-field {
  margin-bottom: 12px;
}

.trek-modal-form-label {
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #333;
}

.trek-modal-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 4px;
  font-size: 14px;
}

.trek-modal-input.error {
  border-color: #dc3545;
}

.trek-modal-error {
  color: #dc3545;
  font-size: 12px;
  display: block;
  margin-top: -2px;
  margin-bottom: 6px;
}

.trek-modal-add-button {
  background-color: #ff6e1e;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  margin-top: 5px;
}

.trek-modal-add-button:hover {
  background-color: #e65c00;
}

.trek-modal-add-link {
  color: #ff6e1e;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.trek-modal-add-link:hover {
  color: #e65c00;
}

.trek-modal-add-new {
  padding: 5px 0;
  text-align: center;
}